.icon-own {
  height: 1.7em;
  fill: var(--color-sportstabs);
}

.icon-own-freespin {
  height: 1.55em;
}

/* .icon-own:active {
  height: 1.7em;
  fill: var(--color-contrast);
} */

.icon-own-drawer {
  height: 1.2rem;
  fill: var(--color-sportstabs);
}

/* .icon-color-1 {
  stop-color: var(--color-icon-2) !important;
}

.icon-color-2 {
  stop-color: var(--color-icon-1) !important;
} */

@media (hover: none) {
  .icon-own-freespin {
    height: 1.1em;
  }
}