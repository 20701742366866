.slips-list-title {
    display: grid;
    width: 100%;
    grid-template-columns: 48% 21% 29% 1%;
    grid-template-rows: 1.75em 1.75em;
    border-bottom: 0.15em solid var(--color-main);
    background-color: var(--grey-7);
    /* padding: 0.5em 0; */
  }
  
/* 
  Setting with share button

  .slips-list-option-btn { 
  display: grid;
  width: 100%;
  grid-template-columns: 20% 20% 20% 40%;
  grid-template-rows: 2.7em;
  border-bottom: 0.1em solid var(--grey-9);
  background-color: var(--grey-7);
} */

.slips-list-option-btn {
  display: grid;
  width: 100%;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 2.7em;
  border-bottom: 0.1em solid var(--grey-9);
  background-color: var(--grey-7);
}

@media (hover: hover) {
  .slips-list-option-btn {
    border-radius: 0.5em 0.5em 0.5em 0.5em;
  }

}