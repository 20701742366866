.featured-game-container {
  margin: 0 0 0.5em 0;
}

.game-teams-container {
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: 43% 14% 43%;
  grid-template-rows: auto;
}

.precanned-game-teams-container {
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: 43% 14% 43%;
  grid-template-rows: auto;
  margin: -0.3em 0 0 0;
}

.featured-game-odds-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}

.featured-outright-game-odds-container {
  align-items: center;
  padding: 0px 3em;
  justify-content: center;
}

.featured-game-odd {
  cursor: pointer;
  /* background-color: var(--bg-odds); */
  border-radius: 0.3em !important;
  border: 1px solid var(--border-odds-featured);
  margin: 0 0 0 0.5em;
  padding: 0.2em 0.5em;
  background-color: var(--bg-odds-featured);
}

.featured-game-odd-spacer {
  color: var(--color-main-featured);
}

.selected-featured-game-odd {
  cursor: pointer;
  /* background-color: var(--bg-odds); */
  background-color: var(--color-contrast);
  color: var(--text-odd-selected) !important;
  padding: 0.2em 1em;
  border-radius: 0.3em;
  margin: 0 0 0 1em;
  margin: 0 0 0 0.5em;
  padding: 0.2em 0.5em;
  border: 1px solid var(--color-contrast);
}

.featured-outright-odd {
  cursor: pointer;
  /* background-color: var(--bg-odds); */
  padding: 0.1em 0.5em;
  border-radius: 0.3em;
  width: 25%;
  text-align-last: center;
  background-color: var(--bg-odds-featured);
}

.featured-game-logo {
  width: 100%;
  text-align: -webkit-center;
}

.featured-outright-logo {
  width: 100%;
  text-align-last: center;
}

.featured-game-team-separator {
  text-align: center;
  font-size: 2em;
  color: var(--grey-4);
  justify-self: center;
  text-align: -webkit-center;
}

.precanned-game-team-separator {
  text-align: center;
  font-size: 1.5em;
  /* color: var(--grey); */
  color: var(--color-main-featured);
  justify-self: center;
  text-align: -webkit-center;
  margin: 0 0 -1em 0;
  font-weight: 600;
}

.featured-game-team-vs {
  font-size: 0.9em;
  font-weight: 600;
  /* color: var(--grey); */
  color: var(--color-main-featured);
}

.featured-game-icon-placeholder {
  font-size: 1.4em;
  margin: 0.2em 0 0.5em 0;
}

.featured-game-odd-selection {
  margin-left: 10px;
  color: var(--color-contrast);
}

.text-align-center {
  text-align: center;
}

.color-odds {
  color: var(--color-odds);
}

.featured-game-team {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.precanned-game-team {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: normal;
}

.precanned-game-team {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.featured-game-team-name {
  color: var(--color-team);
  margin: 0;
  text-align: -webkit-center;
  text-align: center;
  width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
  /* font-size: 1em; */
  font-size: 1.1em;
  /* font-weight: 600; */
  font-weight: 400;
  line-height: normal;
}

.featured-game-icon {
  height: 25px;
  margin-bottom: 12px;
}

.game-v2-league-container {
  background-color: #364459;
  color: #fff;
}

.game-v2-league-container h2 {
  margin: 0;
}

.featured-banner-title {
  color: var(--color-team);
  font-size: 1em;
  font-weight: 600;
}
.featured-banner-title {
  color: var(--color-main-featured);
  font-size: 1em;
  font-weight: 400;
}

/* Features outright container */

.featured-outright-container {
  border-radius: 5px;
}

.featured-outright-league {
  display: flex;
  align-items: center;
}

.featured-outright-league h4 {
  margin-left: 10px;
}

.featured-outright-header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr minmax(2em, auto) 1fr;
  justify-content: space-between;
  margin: 0.5em 0 1em;
}

.featured-game-header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr minmax(2em, auto) 1fr;
  justify-content: space-between;
  margin: 0.5em 0 1em;
}

.featured-outright-info {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  align-items: center;
  justify-content: space-between;
}

.featured-outright-league-name {
  margin: 0;
  color: #fff;
  font-size: 14px;
}

.featured-outright-game-details {
  padding: 0 10px;
  min-height: 8.6em;
}

.featured-outright-game-odds-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.7em;
}

.featured-outright-game-state {
  margin: 10px 0;
}

.featured-outright-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  font-weight: 600;
}

.featured-outright-game-logo {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: AUTO;
  text-align: -webkit-center;
}

/* Featured slips card */

.featured-slip-container {
  padding: 0 0.7em 0 0;
}

.featured-slip-header {
  display: grid;
  width: 100%;
  align-items: center;
  justify-items: center;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  padding: 0 0 0.5em 0;
}

.featured-slip-logo {
  width: 100%;
  text-align: right;
  text-align: -webkit-right;
}

.featured-slip-header-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.featured-slip-header-section h3,
.featured-slip-header-section p {
  margin: 0;
}

.featured-slip-selections-container {
  padding: 0 0.5em;
  height: 12em;
}

.featured-slip-selection-header {
  display: flex;
  align-items: center;
}

.featured-slip-selection-header img {
  width: 22px;
  height: 22px;
}

.featured-slip-selection-header h4 {
  color: #ffffff;
  margin: 0 0 0 10px;
}

.featured-slip-selection-game-name {
  margin: 0 0 !important;
  padding: 0;
  line-height: normal;
}

.featured-slip-selection {
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: 5% 5% auto 15%;
  grid-template-rows: auto;
  padding: 0.2em 0;
}

.featured-slip-selection .icon-own {
  height: 1em;
}

.slick-dots {
  bottom: -2em !important;
  margin: 0;
}

.slick-dots li button::before {
  color: var(--grey) !important;
  font-size: 10px;
}

.slick-dots .slick-active button::before {
  color: var(--color-contrast) !important;
  font-size: 10px;
}

.league_name-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.featured-slip-odds-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.featured-slip-odd {
  padding-left: 1em;
  text-align-last: end;
  font-size: 0.8em;
}

.featured-slip-outright-odd {
  padding-left: 1em;
  text-align-last: end;
  font-size: 1em;
}

.total-odds-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 0.6em 0;
}

.total-odds-sub-container {
  display: flex;
  align-items: center;
  width: max-content;
  background-color: var(--bg-odds);
  border-bottom-left-radius: 0.5em;
  padding: 0.25em 0.6em;
  border-radius: 0.3em;
}

@media (hover: hover) {
  .featured-outright-game-odds-row :hover {
    background-image: var(--odd-hover);
    background-repeat: no-repeat;
    border-radius: 5px;
  }

  .featured-game-container {
    margin: 0 0.5em;
    /* background-color: var(--bg-stake); */
    border-radius: 0.5em;
    /* background: linear-gradient(to bottom, var(--bg-featured-banner), var(--color-main)); */
    background-color: var(--bg-stake);
    padding: 0.3em;
  }

  .featured-game-header {
    margin: 0 0.5em 0.5em;
    padding: 0.5em 0 0 0;
    width: 97%;
  }

  .featured-outright-container {
    margin: 0 0.5em;
    padding: 0.3em;
    /* background-color: var(--bg-stake); */
    border-radius: 0.5em;
    height: 15em;
    /* background: linear-gradient(to bottom, var(--bg-featured-banner), var(--color-main)); */
    background-color: var(--bg-stake);
  }

  .featured-outright-header {
    margin: 0 0.5em 0.5em;
    padding: 0.5em 0 0 0;
    width: 97%;
  }

  .featured-slip-container {
    margin: 0 0.5em;
    /* background-color: var(--bg-stake); */
    border-radius: 0.5em;
    height: 16em;
    padding: 0 0.7em;
    /* background: linear-gradient(to bottom, var(--bg-featured-banner), var(--color-main)); */
  }

  .featured-slip-header {
    margin: 0 0.5em 0.5em;
    padding: 0.5em 0 0 0;
    width: 97%;
  }

  .featured-slip-selections-container {
    height: 12em;
    padding: 0.5em 0 0 0;
  }
}

.total-odds-text {
  color: var(--grey);
  padding: 0 2em 0 0;
  font-weight: 800;
  margin-top: 0.1em;
}

.total-odds-text {
  color: var(--grey);
  font-weight: 800;
  margin-top: 0.1em;
}

.total-odds-odd {
  text-align-last: end;
  font-size: 1.2em;
  font-weight: 800;
}

.slip-card-title {
  text-align: center;
  margin: 0.2em 0 0.6em;
}

.precanned-card-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1em;
}

/* .precanned-timeline-container {
  padding: 0.5em 0 0 3.2em;
  color: var(--color-odds);
  font-size: 1em;
  font-weight: 600;
} */
.precanned-timeline-container {
  padding: 0.5em 0 0 3.2em;
  color: var(--grey);
  font-size: 1em;
  font-weight: 400;
}

.precanned-total-odds-container {
  /* background: var(--bg-odds); */
  background: var(--bg-odds-featured);
  padding: 0.25em 0.5em;
  border-radius: 0.3em;
}

.featured-slip-total-odds-container {
  display: grid;
  width: fit-content;
  align-items: center;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
}

/* .rotated {
  transform: rotate(90deg);
  color: var(--color-odds);
} */
.rotated {
  transform: rotate(90deg);
  color: var(--grey);
}

.team-avatar {
  width: 1.7em;
  height: 1.6em;
}

.precanned-game-teams-container .competitor-avatar-img {
  width: 1em;
  height: 1em;
}

.prev-odd {
  text-decoration: line-through;
  -webkit-text-decoration-color: var(--color-odds);
  text-decoration-color: var(--color-odds);
  -webkit-text-decoration-thickness: 0.1em;
  text-decoration-thickness: 0.1em;
  opacity: 0.7;
}
