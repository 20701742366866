.ui.container {
  background: var(--bg-app);
  color: var(--grey);
  min-height: 100vh;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%;
}

body {
  font-family: Ubuntu;
  background: var(--bg-app);
  font-size: var(--small);
  margin: 0;
  /* scrollbar-width: thin; */
  /* scrollbar-color: var(--color-contrast) var(--bg-app); */
  overflow-x: hidden;
}

img {
  height: 1em;
}

h1 {
  color: var(--color-contrast);
  margin-top: 0;
  font-size: var(--h1);
  padding-top: 0.5em;
}

h6 {
  width: 100%;
  text-align: center;
  /* border-bottom: 1px solid var(--grey-2); */
  line-height: 0.1em;
  margin: 1em 0;
  font-size: 0.8em;
  font-weight: 400;
}

h6 span {
  background: var(--bg-primary);
  padding: 0 1em;
}

h1 {
  color: var(--color-contrast);
}

/* h6 {
  color: var(--color-contrast);
} */

.color-contrast {
  color: var(--color-contrast);
}

.color-contrast-slip {
  color: var(--color-contrast-slip);
}

.color-selection-slip {
  color: var(--color-selection-slip);
}
.color-odds {
  color: var(--color-odds);
}

.color-main {
  color: var(--color-main);
}

.color-time {
  color: var(--color-time);
}

.color-time-live {
  color: var(--color-time-live);
}

.color-stake {
  color: var(--color-stake);
}

.color-team {
  color: var(--color-team);
}

.color-tr {
  color: var(--color-tr);
}

.green {
  color: var(--green);
}

.grey-3 {
  color: var(--grey-3);
}

.grey-permanent {
  color: #535353;
}

.red {
  color: var(--red);
}
.yellow {
  color: var(--yellow-1);
}

.white {
  color: var(--color-main);
}

.white-permanent {
  color: var(--white);
}

.black-permanent {
  color: var(--black);
}

.bg-color-contrast {
  background-color: var(--color-contrast);
}

.bg-color-main {
  background-color: var(--color-main);
}

.bg-color-contrast-gradient-right {
  background-image: var(--bg-teams-sidebets-right);
}

.bg-color-contrast-gradient-left {
  background-image: var(--bg-teams-sidebets-left);
}

.bg-green {
  background-color: var(--green);
}

.bg-grey-1 {
  background-color: var(--grey-1);
}

.bg-grey-2 {
  background-color: var(--grey-2);
}

.bg-grey-3 {
  background-color: var(--grey-3);
}

.bg-grey-7 {
  background-color: var(--grey-7);
}

.bg-grey-8 {
  background-color: var(--bg-odds);
}

.bg-red {
  background-color: var(--red);
}

.bg-grey-14 {
  background-color: var(--grey-14);
}

.bg-light {
  /* background: var(--color-main); */
  background: var(--bg-stake);
}

.bg-light-gradient-center {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
}

.bg-light-gradient-left {
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.bg-light-gradient-right {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.bg-light-gradient-detailed-left {
  background-image: linear-gradient(to left, rgba(42, 45, 66, 0), rgba(255, 255, 255, 0.233));
}

.bg-light-gradient-detailed-right {
  background-image: linear-gradient(to right, rgba(42, 45, 66, 0), rgba(255, 255, 255, 0.233));
}

.bg-light-gradient-conference {
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8));
}

.bg-light-gradient-conference-1 {
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.55));
}

.bg-light-trans {
  background-color: var(--trans-1);
}

.font-xs {
  font-size: 0.9em;
}

.font-s {
  font-size: 1em;
}

.font-m {
  font-size: 1.1em;
}

.font-l {
  font-size: 1.2em;
}

.font-xl {
  font-size: 1.3em;
}

.font-xxl {
  font-size: 1.4em;
}

.font-xxxl {
  font-size: 1.5em;
}

.font-xxxxl {
  font-size: 1.6em;
}

.font-xxxxxl {
  font-size: 2.3em;
}

.font-bold {
  font-weight: 400;
}

.font-bold-2 {
  font-weight: bold;
}

.font-equalizer {
  font-size: 1.7em !important;
}

.font-cancel {
  font-size: 1.8em !important;
  margin-top: 0.1em !important;
}

.font-slip-close {
  font-size: 1.8em !important;
}

.font-odds-tv {
  font-size: 1em;
}

.font-odds-label-tv {
  font-size: 0.8em;
  color: var(--color-contrast);
}

.font-blur {
  filter: blur(2px);
}

.font-time {
  font-size: 1em;
}

.font-time-1 {
  font-size: 0.95em;
}

.font-hc {
  font-size: 1em;
  /* color: var(--color-hc); */
}

.font-hc-hor {
  font-size: 1.2em;
}

.font-detail {
  font-size: 0.95em;
}

.font-detail-1 {
  font-size: 1em;
}

.font-standing {
  font-size: 1em;
}

.font-team {
  font-size: 0.9em;
}

.font-title-outright {
  font-size: 1.2em;
}

a {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.text-wrap {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}

.nowrap {
  text-wrap: nowrap;
}

.text-wrap-outright-result {
  width: 100%;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  white-space: break-spaces;
}

.word-wrap {
  word-break: break-word;
}

.text-last {
  text-align-last: right;
}

.text-last-center {
  text-align-last: center;
  text-align: -webkit-center;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.content-center {
  justify-content: center;
}

.content-left {
  justify-content: flex-start;
}

.content-right {
  justify-content: flex-end;
}

.self-center {
  justify-self: center;
}

.content-uppercase {
  text-transform: uppercase;
}

.spacer-left {
  padding-left: 0.4em;
}

.spacer-left-list {
  padding-left: 0.4em;
}

.spacer-left-time {
  padding-left: 0.4em;
}

.spacer-right {
  padding-right: 0.4em;
}

.spacer-right-2 {
  margin-right: 0.4em;
}
.spacer-right-slip {
  margin-right: 0.8em;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.border-radius {
  border-radius: 0.25em;
}

.text-shadow {
  text-shadow: 0.1em 0.1em var(--trans-2);
}

.input-box {
  border: 0.05em solid var(--grey-3);
}

.cursor-pointer {
  cursor: pointer;
}

.hidden {
  display: none;
}

.div-hidden {
  visibility: hidden;
}

.green-button {
  background-color: var(--color-contrast-5) !important;
  color: var(--btn-text) !important;
  /* background: linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%); */
}

.green-button-1 {
  background-color: var(--color-contrast-1) !important;
}

.green-button-1:hover {
  background-color: var(--color-contrast-3) !important;
}

.login-button {
  background: linear-gradient(180deg, rgba(214, 214, 214, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(214, 214, 214, 1) 100%);
}

.login-button:hover {
  background: var(--white);
}

.disabled-button {
  background: var(--grey-19) !important;
  color: var(--grey-11) !important;
  width: 100% !important;
  margin: 0.5em 0 0.5em !important;
}

.active-button {
  /* background-color: var(--color-contrast) !important; */
  background: linear-gradient(0deg, rgba(29, 88, 154, 1) 0%, rgba(61, 163, 253, 1) 50%, rgba(29, 88, 154, 1) 100%) !important;
  color: var(--btn-text) !important;
  width: 100% !important;
  margin: 0.5em 0 0.5em !important;
}

.gray-button {
  background-color: var(--grey) !important;
  color: black !important;
}

.gray-button-hover {
  background-color: var(--red) !important;
  color: var(--white) !important;
}

.red-button {
  background-color: var(--red) !important;
  color: var(--white) !important;
}

.cookie-box:hover {
  color: var(--white);
  text-decoration: underline;
}

.slip-ticket-button {
  background-color: var(--bg-slip-switch);
  color: var(--btn-text);
  display: flex;
  align-items: center;
  justify-content: center;
}

.slip-ticket-button:hover {
  background-color: var(--bg-slip-switch-hover);
}

/* MUI OVERWRITES */

.MuiBottomNavigation-root {
  height: 66px !important;
}

.MuiBottomNavigationAction-label {
  font-size: 0.7rem !important;
}

.MuiBottomNavigationAction-label.Mui-selected {
  font-size: 0.7rem !important;
}

.MuiTab-root {
  text-transform: none !important;
}

.MuiSvgIcon-root {
  vertical-align: top;
  font-size: 1.5em;
}

.MuiTab-wrapper {
  display: block !important;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
  overflow: hidden;
}

.react-swipeable-view-container > div[aria-hidden="false"] {
  height: 100%;
}

.react-swipeable-view-container > div[aria-hidden="true"] {
  height: 0;
}

/* MAD-RIPPLE EFFECT */
.ripple {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  -webkit-transform: translateZ(0);
  /* to contain zoomed ripple */
  transform: translateZ(0);
  border-radius: inherit;
  /* inherit from parent (rounded buttons etc) */
  pointer-events: none;
  /* allow user interaction */
}

.rippleWave {
  backface-visibility: hidden;
  position: absolute;
  /* border-radius: 50%; */
  transform: scale(0.7);
  -webkit-transform: scale(0.7);
  background: rgba(255, 255, 255, 1);
  opacity: 0.45;
  animation: ripple 0.9s forwards;
  -webkit-animation: ripple 0.9s forwards;
}

@keyframes ripple {
  to {
    transform: scale(24);
    opacity: 0;
  }
}

@-webkit-keyframes ripple {
  to {
    -webkit-transform: scale(24);
    opacity: 0;
  }
}

#legalcontent li:before {
  content: "–";
  color: var(--color-contrast);
  padding-right: 0.5em;
  align-self: flex-start;
}

#legalcontent a {
  color: var(--color-contrast);
  text-decoration: underline;
}

#legalcontent > h1 {
  color: var(--color-contrast);
  font-size: 1.3em;
}
#promotioncontent > h1 {
  color: var(--grey);
  font-size: 1em;
}
#promotioncontent > h3 {
  color: var(--grey);
  font-size: 1em;
}

#promocontent > h1 {
  color: var(--yellow-1);
  font-size: 1.3em;
  text-transform: uppercase;
}

#legalcontent > h3 {
  color: var(--color-contrast);
  font-size: 1.3em !important;
}

#legalcontent > h4 {
  text-decoration: underline;
  font-size: 1.1em;
}

#auto-identification {
  color: var(--grey-3) !important;
  text-decoration: underline;
}

.small-font {
  font-size: 10px !important;
}

#nav_confirm_pw {
  text-decoration: underline;
}

/* Infobox Mainpage */

.infobox-container {
  background-color: var(--bg-menu-top);
  color: var(--color-infobox);
  font-size: 1em;
  padding: 1em 1em 0;
  overflow: hidden;
}

.infobox-text {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  text-wrap: nowrap;

  -moz-animation: infotext-scroll 60s linear infinite;
  -webkit-animation: infotext-scroll 60s linear infinite;
  animation: infotext-scroll 60s linear infinite;
}

/* for Firefox */
@-moz-keyframes infotext-scroll {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes infotext-scroll {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes infotext-scroll {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

/* Cookie Consent Banner */

.react-cookie-law-dialog {
  bottom: 0;
  padding: 0 4vh !important;
  top: initial !important;
  background-color: var(--color-main) !important;
}

.react-cookie-law-msg h2 {
  color: var(--color-contrast) !important;
  margin-block-start: 0.3em !important;
  line-height: 1.1 !important;
}

.react-cookie-law-msg p {
  color: var(--grey) !important;
  text-align: justify !important;
  margin-block-end: 1em !important;
  line-height: 1.2 !important;
}

.react-cookie-law-policy {
  display: none;
}

.react-cookie-law-manage-btn {
  border: none !important;
  background-color: var(--grey) !important;
  border-radius: 0.3em;
  padding: 7px 10px !important;
  color: var(--grey-7) !important;
}

.react-cookie-law-save-btn {
  border: none !important;
  background-color: var(--color-contrast) !important;
  border-radius: 0.3em;
  padding: 7px 10px !important;
  color: var(--btn-text) !important;
}

.react-cookie-law-accept-btn {
  border: none !important;
  background-color: var(--color-contrast) !important;
  border-radius: 0.3em;
  padding: 7px 10px !important;
  margin-right: 0 !important;
  color: var(--btn-text) !important;
}

.react-cookie-law-option-checkbox {
  top: 0 !important;
  left: -5px !important;
  color: var(--color-contrast) !important;
}

#check-required-cookies input:disabled {
  top: 0 !important;
  left: -5px !important;
  color: var(--color-contrast) !important;
}

input.react-cookie-law-option-checkbox:checked {
  top: 0 !important;
  left: -5px !important;
  color: var(--red) !important;
}

.react-cookie-law-option-wrapper label {
  color: var(--color-contrast) !important;
  font-size: 10pt !important;
}

.react-cookie-law-option-wrapper {
  padding: 0.1em 0 1em 0 !important;
}

.react-cookie-law-button-wrapper {
  padding: 0 0 2em 0 !important;
}

/* Retail Card */

.retailcard-container {
  padding: 2em;
  text-align: -webkit-center;
}

.retailcard-qr {
  padding: 2em 2em 1em 2em;
  text-align: right;
}

.retailcard-owner {
  font-size: 2em;
  font-family: "Courier New", Courier, monospace;
  color: var(--white);
  padding: 0 0 1em 1em;
}

.retailcard-username {
  font-size: 2em;
  font-family: "Courier New", Courier, monospace;
  color: var(--white);
  padding: 0 0 0 1em;
}

.retailcard {
  width: 40%;
  margin: 3em;
  border-radius: 2em;
  text-align: left;
  box-shadow: 0.1em 0.3em 0.5em 0.3em rgb(0 0 0 / 47%);
  background: url(../../media/img/bg_card_pattern.png), linear-gradient(0deg, var(--grey-8) 0%, var(--color-contrast) 100%);
}

.MuiRadio-colorSecondary.Mui-checked {
  color: inherit !important;
}

.font-release-version {
  color: var(--grey-3);
  padding: 0 0 0 0.3em;
  font-size: 0.7em;
}

.font-release-date {
  color: var(--grey-3);
  text-align: right;
  font-size: 0.9em;
}

.lightbox-banner {
  height: 50em;
  background-size: 100%;
  /* border: 1px solid var(--color-contrast); */
}

.lightbox-close {
  width: 100%;
  text-align-last: right;
}

.promo-banner {
  opacity: 1;
  cursor: pointer;
  border-radius: 0.5em 0 0 0.5em;
}

.promo-banner:hover {
  opacity: 0.8;
}

/* .live-pulse {
  color: var(--red) !important;
} */

.live-pulse {
  -webkit-animation: color-pulse 2s infinite;
  -moz-animation: color-pulse 2s infinite;
  -o-animation: color-pulse 2s infinite;
  -ms-animation: color-pulse 2s infinite;
  animation: color-pulse 2s infinite;
}

@-webkit-keyframes color-pulse {
  0% {
    color: var(--color-pulse-1);
  }

  50% {
    color: var(--color-pulse-2);
  }

  100% {
    color: var(--color-pulse-1);
  }
}

@-moz-keyframes color-pulse {
  0% {
    color: var(--color-pulse-1);
  }

  50% {
    color: var(--color-pulse-2);
  }

  100% {
    color: var(--color-pulse-1);
  }
}

@-ms-keyframes color-pulse {
  0% {
    color: var(--color-pulse-1);
  }

  50% {
    color: var(--color-pulse-2);
  }

  100% {
    color: var(--color-pulse-1);
  }
}

@-o-keyframes color-pulse {
  0% {
    color: var(--color-pulse-1);
  }

  50% {
    color: var(--color-pulse-2);
  }

  100% {
    color: var(--color-pulse-1);
  }
}

@keyframes color-pulse {
  0% {
    color: var(--color-pulse-1);
  }

  50% {
    color: var(--color-pulse-2);
  }

  100% {
    color: var(--color-pulse-1);
  }
}

.casino-slider-container {
  text-align: -webkit-center;
}

@media (hover: none) {
  /* .bluedark {
    --szf: 11.5vw !important;
  } */
  .color-time {
    color: var(--color-time-mobile);
  }

  .color-time-live {
    color: var(--color-time-live);
  }

  .font-time {
    font-size: 1em;
  }

  .font-time-1 {
    font-size: 0.95em;
  }

  .font-time-2 {
    font-size: 0.85em !important;
  }

  .font-hc {
    font-size: 1em;
  }

  .font-detail {
    font-size: 0.95em;
  }

  .font-detail-1 {
    font-size: 1em;
  }

  .font-standing {
    font-size: 1em;
  }

  .font-team {
    font-size: 1.05em;
  }

  .retailcard {
    width: fit-content;
    text-align: center;
  }

  .retailcard-owner {
    padding: 0 0 1em 0em;
  }

  .retailcard-qr {
    padding: 2em 2em 0 2em;
    text-align: center;
  }

  .lightbox-banner {
    height: 50em;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .font-detail {
    font-size: 1em;
  }

  .infobox-text {
    /* animation properties */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    text-wrap: nowrap;
    width: 200%;

    -moz-animation: infotext-scroll 80s linear infinite;
    -webkit-animation: infotext-scroll 80s linear infinite;
    animation: infotext-scroll 80s linear infinite;
  }

  /* for Firefox */
  @-moz-keyframes infotext-scroll {
    from {
      -moz-transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-300%);
    }
  }

  /* for Chrome */
  @-webkit-keyframes infotext-scroll {
    from {
      -webkit-transform: translateX(100%);
    }
    to {
      -webkit-transform: translateX(-300%);
    }
  }

  @keyframes infotext-scroll {
    from {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-300%);
      -webkit-transform: translateX(-300%);
      transform: translateX(-300%);
    }
  }
  .promo-banner {
    border-radius: 0;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}
