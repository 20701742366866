h3 {
    color: var(--color-contrast);
    font-size: 1.2em;
}

h4 {
    color: var(--color-contrast);
    font-size: 1.2em;
    margin-top: 0.2em;
}

a:hover {
    color: var(--color-contrast);
}

/* img {
    opacity: 0.5;
} */
/* img:hover {
    opacity: 1;
} */
