.game-compact-column {
  display: grid;
  width: 100%;
  grid-template-rows: 1.2em 1.2em;
  border-top: 1px solid var(--border-gamebox);
  padding: 0.7em 0 0 0;
  background: var(--title-game-gradient);
  /* height: auto; */
}

/* .game-compact-column-outright-results {
  display: grid;
  width: 100%;
  grid-template-rows: 2.1em 2.1em;
  border-top: 1px solid var(--border-gamebox);
  background: var(--title-game-gradient);
  font-size: 0.95em !important;
} */

.game-compact-column-outright-results {
  grid-template-columns: 0em auto auto 0px 0px 0px 1em !important;
  display: grid;
  width: 100%;
  grid-template-rows: auto;
  border-radius: 0.5em;
  background: var(--bg-results);
  font-size: 0.95em !important;
  padding: 0.3em 0;
}

.game-compact-column-column {
  display: grid;
  width: 100%;
  /* grid-template-rows: 1.3em 1.3em; */
  /* grid-template-rows: 1.3em auto; */
  grid-template-rows:  auto;
  border-top: 1px solid var(--border-gamebox);
  padding: 0.3em 0 0.3em 0;
  background: var(--title-game-gradient);
}

.game-compact-column-conference {
  display: grid;
  width: 100%;
  grid-template-rows: 1.5em 1.5em;
  padding: 0.3em 0 0.3em 0;
  background-image: var(--title-game-gradient-conference);
}

.game-compact-column-tv {
  display: grid;
  width: 100%;
  grid-template-rows: 1.3em 1.3em;
  /* background-color: var(--grey-8); */
  /* border-top: 1px solid var(--border-gamebox); */
  padding: 0.15em 0;
  background-image: var(--title-game-gradient);
}

/* .game-compact-column-tv-title {
  display: grid;
  width: 100%;
  grid-template-columns: 4.7em 1.5em 11em 1em 1.5em 1.8em auto 60% 2em;
  grid-template-rows: 1.3em;
  padding: 0.15em 0;
  background: var(--color-contrast);
  color: var(--bg-app);
} */

.game-box-even-row {
  background-image: var(--title-game-gradient-1);
}

.game-divider-badge {
  grid-row-end: span 2;
  height: 3em;
}

.game-divider-time {
  grid-row-start: span 2;
}

.game-title-date {
  padding: 0 0 0.3em 0.2em;
  background-color: var(--title-game-gradient);
}

.game-box-detailed-title1 {
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
  /* grid-template-rows: 2em; */
}

.game-box-detailed-title {
  display: grid;
  width: 100%;
  grid-template-columns: 29% 40% 29%;
  grid-template-rows: 1.5em 1em 1em;
}

.game-box-detailed-additional-info {
  display: grid;
  width: 100%;
  grid-template-columns: 100%;
  grid-template-rows: 1.9em;
  padding: 0.3em 0 0 0;
}

.game-box-detailed-summary {
  display: grid;
  width: 100%;
  grid-template-columns: 100%;
  grid-template-rows: 2em 2em;
  background-color: #2a75dd41;
}

.game-box-detailed-bg {
  margin-top: -57px;
  height: 9.4em;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.bg-AF {
  background-image: url(../../../../media/img/bg_gamebox_large_AF.jpg);
  background-repeat: no-repeat !important;
}

.bg-AR {
  background-image: url(../../../../media/img/bg_gamebox_large_RG.jpg);
  background-repeat: no-repeat !important;
}

.bg-BA {
  background-image: url(../../../../media/img/bg_gamebox_large_BA.jpg);
  background-repeat: no-repeat !important;
}

.bg-BB {
  background-image: url(../../../../media/img/bg_gamebox_large_BB.jpg);
  background-repeat: no-repeat !important;
}

.bg-BD {
  background-image: url(../../../../media/img/bg_gamebox_large_IH.jpg);
  background-repeat: no-repeat !important;
}

.bg-BM {
  background-image: url(../../../../media/img/bg_gamebox_large_BM.jpg);
  background-repeat: no-repeat !important;
}

.bg-BX {
  background-image: url(../../../../media/img/bg_gamebox_large_BX.jpg);
  background-repeat: no-repeat !important;
}

.bg-BV {
  background-image: url(../../../../media/img/bg_gamebox_large_BV.jpg);
  background-repeat: no-repeat !important;
}

.bg-CR {
  background-image: url(../../../../media/img/bg_gamebox_large_CR.jpg);
  background-repeat: no-repeat !important;
}

.bg-CS {
  background-image: url(../../../../media/img/bg_gamebox_large_CS.jpg);
  background-repeat: no-repeat !important;
}

.bg-CU {
  background-image: url(../../../../media/img/bg_gamebox_large_CU.jpg);
  background-repeat: no-repeat !important;
}

.bg-DA {
  background-image: url(../../../../media/img/bg_gamebox_large_DA.jpg);
  background-repeat: no-repeat !important;
}

.bg-DT {
  background-image: url(../../../../media/img/bg_gamebox_large_DT.jpg);
  background-repeat: no-repeat !important;
}

.bg-FB {
  background-image: url(../../../../media/img/bg_gamebox_large_FB.jpg);
  background-repeat: no-repeat !important;
}

.bg-FL {
  background-image: url(../../../../media/img/bg_gamebox_large_FL.jpg);
  background-repeat: no-repeat !important;
}

.bg-FS {
  background-image: url(../../../../media/img/bg_gamebox_large_FB.jpg);
  background-repeat: no-repeat !important;
}

.bg-HB {
  background-image: url(../../../../media/img/bg_gamebox_large_HB.jpg);
  background-repeat: no-repeat !important;
}

.bg-HR {
  background-image: url(../../../../media/img/bg_gamebox_large_HR.jpg);
  background-repeat: no-repeat !important;
}

.bg-IH {
  background-image: url(../../../../media/img/bg_gamebox_large_IH.jpg);
  background-repeat: no-repeat !important;
}

.bg-LL {
  background-image: url(../../../../media/img/bg_gamebox_large_DT.jpg);
  background-repeat: no-repeat !important;
}

.bg-MM {
  background-image: url(../../../../media/img/bg_gamebox_large_MM.jpg);
  background-repeat: no-repeat !important;
}

.bg-MO {
  background-image: url(../../../../media/img/bg_gamebox_large_MO.jpg);
  background-repeat: no-repeat !important;
}

.bg-PS {
  background-image: url(../../../../media/img/bg_gamebox_large_PS.jpg);
  background-repeat: no-repeat !important;
}

.bg-RG {
  background-image: url(../../../../media/img/bg_gamebox_large_RG.jpg);
  background-repeat: no-repeat !important;
}

.bg-SN {
  background-image: url(../../../../media/img/bg_gamebox_large_SN.jpg);
  background-repeat: no-repeat !important;
}

.bg-SQ {
  background-image: url(../../../../media/img/bg_gamebox_large_SQ.jpg);
  background-repeat: no-repeat !important;
}

.bg-TE {
  background-image: url(../../../../media/img/bg_gamebox_large_TE.jpg);
  background-repeat: no-repeat !important;
}

.bg-TR {
  background-image: url(../../../../media/img/bg_gamebox_large_TR.jpg);
  background-repeat: no-repeat !important;
}

.bg-TT {
  background-image: url(../../../../media/img/bg_gamebox_large_TT.jpg);
  background-repeat: no-repeat !important;
}

.bg-VB {
  background-image: url(../../../../media/img/bg_gamebox_large_VB.jpg);
  background-repeat: no-repeat !important;
}

.bg-WP {
  background-image: url(../../../../media/img/bg_gamebox_large_WP.jpg);
  background-repeat: no-repeat !important;
}


.game-box-detailed-team1 {
  grid-column: 1;
  grid-row: 1 / 3;
}

.game-box-detailed-team2 {
  grid-column: 3;
  grid-row: 1 / 3;
}

.game-box-detailed-additional-state-info-item {
  display: grid;
  width: 100%;
  grid-template-columns: 4% 4% auto;
  grid-template-rows: 1.9em;
}

.game-box-conference-additional-info {
  width: 100%;
  padding: 0em 0 0.6em 0em;
}

.game-box-conference-countdown {
  width: 100%;
}

.game-box-conference-countdown-info {
  position: relative;
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: bold;
  letter-spacing: 0.2em;
  padding: 0.5em;
  overflow: hidden;
  background: linear-gradient(90deg, #5e895a, #fff, var(--color-contrast-1));
  background-repeat: no-repeat;
  background-size: 80%;
  animation: countdownAni 5s linear infinite;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes countdownAni {
  0% {
    background-position: -500%;
  }

  100% {
    background-position: 500%;
  }
}

.game-box-conference-countdown-time {
  padding: 0.3em 0;
  text-shadow: 0.05em 0.05em var(--trans-2);
}

.game-box-conference-additional-state-info-item {
  display: grid !important;
  width: 100%;
  grid-template-columns: 0.8% 18% 0.8% 20% 5% 5% auto 4%;
  grid-template-rows: 1.5em;
  margin: 0 0 0.2em 0;
  border-radius: 0 0.3em 0.3em 0;
}

.game-box-conference-additional-state-info-item1 {
  display: grid !important;
  width: 100%;
  grid-template-columns: 0.8% 17% 0.8% 19% 5% 5% auto 8.9%;
  grid-template-rows: 2.8em;
  margin: 0 0 0.3em 0;
  border-radius: 0.2em;
}

.game-box-detailed-additional-state-info-item-left {
  display: grid;
  width: 100;
  /* grid-template-columns: 3% 3% auto; */
  grid-template-columns: 3% auto;
  grid-template-rows: 1.5em;
  margin: 0.2em 0 0 0;
}

.game-box-detailed-additional-state-info-item-right {
  display: grid;
  width: 100%;
  /* grid-template-columns: auto 3% 3%; */
  grid-template-columns: auto 3%;
  grid-template-rows: 1.5em;
  margin: 0.2em 0 0 0;
}

.game-box-conference-bg {
  background-image: url(../../../../media/img/bg_gamebox_large_conference_FB.jpg);
  background-size: cover;
  /* margin-top: -5.6em; */
  min-height: 16.2em;
}

.game-box-conference-title {
  display: grid;
  width: 100%;
  grid-template-columns: auto 20%;
  /* grid-template-columns: min-content auto; */
  grid-template-rows: 2em;
}

.game-box-conference-score-info {
  display: grid;
  width: 100%;
  grid-template-columns: 1% 13% 17% 13% 1% auto;
  /* grid-template-columns: min-content auto; */
  grid-template-rows: 1.4em;
  margin: 0.3em 0 0 0;
}

.equalizer {
  justify-self: flex-end;
}

.cancel {
  padding-left: 0.2em;
}

.MuiTypography-button {
  font-size: 1em !important;
}

.margin-top-avatar {
  margin: 0.1em 0 0 0;
}

.MuiTypography-gutterBottom {
  margin-bottom: 0 !important;
}

/* .game_goal_bg {
  background-color: var(--green-2);
}

.game_goal_animation {
  background-color: var(--green-2);
  animation-name: keyframes_goal;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes keyframes_goal {
  0% { background-color: var(--green-2);}
  50% {background-color: var(--color-main);}
  100% {background-color: var(--green-2);}
}


.football-state-background-danger {
  background-color: var(--red-2);
}

.game_danger_animation {
  background-color: var(--red-2);
  animation-name: keyframes_danger;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes keyframes_danger {
  0% { background-color: var(--red-2);}
  50% {background-color: var(--color-main);}
  100% {background-color: var(--red-2);}
} */

.football-state-icon-safe,
.football-state-icon-attack {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_safe.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.football-state-icon-safe-white,
.football-state-icon-attack-white {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_safe_white.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.football-state-icon-dangerous-attack {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_dangerous_attack.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.football-state-icon-dangerous-attack-white {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_dangerous_attack.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.football-state-icon-shot {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_shot_on_target.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.football-state-icon-shot-white {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_shot_on_target_white.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.football-state-icon-pole {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_pole.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.football-state-icon-pole-white {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_pole_white.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.football-state-icon-free-kick,
.football-state-icon-attacking-free-kick,
.football-state-icon-dangerous-free-kick {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_freekick.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.football-state-icon-free-kick-white,
.football-state-icon-attacking-free-kick-white,
.football-state-icon-dangerous-free-kick-white {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_freekick_white.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.football-state-icon-corner {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_corner.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.football-state-icon-corner-white {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_corner.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.football-state-icon-pole {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_pole.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.football-state-icon-penalty {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_penalty.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.football-state-icon-goal {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_goal.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.football-state-icon-yellow-card {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_card_yellow.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.football-state-icon-red-card {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_card_red.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.football-state-icon-substitution {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_substitution.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.football-state-icon-var {
  background-image: url(https://api.nubet.com/media/icons/pic_danger_state_var.svg);
  background-repeat: no-repeat;
  justify-self: center;
  background-position: center;
}

.tennis-state-icon-current-server {
  background-color: var(--yellow);
  border-radius: 1em;
  height: 0.7em;
  color: transparent;
  width: 0.7em !important;
  margin: 0.4em 0 0 0 !important;
}

.delay {
  animation-delay: 300ms;
}

.fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.MuiListItemIcon-root {
  /* color: var(--color-contrast) !important; */
}

.MuiListItem-gutters {
  padding-left: 0.6em !important;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
    color: #48a868;
  }

  50% {
    opacity: 0;
    color: transparent;
  }

  100% {
    opacity: 1;
    color: #48a868;
  }
}

@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
    color: #48a868;
  }

  50% {
    opacity: 0;
    color: transparent;
  }

  100% {
    opacity: 1;
    color: #48a868;
  }
}

@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
    color: #48a868;
  }

  50% {
    opacity: 0;
    color: transparent;
  }

  100% {
    opacity: 1;
    color: #48a868;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
    color: #48a868;
  }

  50% {
    opacity: 0;
    color: transparent;
  }

  100% {
    opacity: 1;
    color: #48a868;
  }
}

.animate-flicker {
  -webkit-animation: flickerAnimation 1s 5;
  -moz-animation: flickerAnimation 1s 5;
  -o-animation: flickerAnimation 1s 5;
  animation: flickerAnimation 1s 5;
}

@media (hover: hover) {
  .game-box-conference-additional-state-info-item1.font-m {
    font-size: 1.5em;
  }

  .game-box-conference-additional-state-info-item1 {
    grid-template-columns: 0.8% 13% 0.8% 14% 3% 3% auto 2.5em;
    grid-template-rows: 1.95em;
  }

  .game-box-conference-score-info {
    grid-template-columns: 1% 10.7% 12% 10.7% 1% auto;
  }
  .game-compact-column-outright-results {
    grid-template-columns: 0em auto auto 0px 0px 0px 0 1em !important;
  }
}

@media (hover: none) {
  .game-title-date {
    padding: 0;
  }
}

.center-arrow-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0 !important;
}

.suomi_time_changer span {
  text-transform: capitalize;
}

.sidbets_teams{
  display: flex;
  flex-direction: column;
  align-items: center;
}