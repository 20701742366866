.marketset-grid-1-cols {
  display: grid;
  width: 100%;
  grid-template-columns: 100%;
  grid-template-rows: 3.2em;
  /* margin: 0.2em 0 0 0; */
}

.marketset-grid-2-cols {
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
  grid-template-rows: 3.2em;
  /* margin: 0.2em 0 0 0; */
}

.marketset-playerprops-grid-2-cols {
  display: grid;
  width: 100%;
  grid-template-columns: 40% 60%;
  grid-template-rows: 3.2em;
  /* margin: 0.2em 0 0 0; */
}

.marketset-grid-3-cols {
  display: grid;
  width: 100%;
  grid-template-columns: 33% 33% 34%;
  grid-template-rows: 3.2em;
  /* margin: 0.2em 0 0 0; */
}

.marketset-grid-4-cols {
  display: grid;
  width: 100%;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: 3.2em;
  /* margin: 0.2em 0 0 0; */
}

.marketset-grid-4-cols-goalscorer {
  display: grid;
  width: 100%;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: 3.2em;
  margin: 0.2em 0 0 0;
}

.market-box-3-cols {
  display: grid;
  width: 100%;
  /* grid-template-columns: 0.1% 11% 18% 11% 18% 11% 18% 10%; SETTING FOR ODDS HISTORY CHART */
  grid-template-columns: 0.1% 11% 21% 11% 21% 11% 21% 0%;
  /* grid-template-rows: 3em; */
  grid-template-rows: 3.2em;
  margin: 0.15em 0 0 0;
}

.market-box-3-cols-tv {
  display: grid;
  width: 100%;
  /* grid-template-columns: 0.1% 11% 18% 11% 18% 11% 18% 10%; SETTING FOR ODDS HISTORY CHART */
  grid-template-columns: 0.1% 11% 21% 11% 21% 11% 21% 0%;
  grid-template-rows: 2.6em;

}

.market-box-2-cols {
  display: grid;
  width: 100%;
  /* grid-template-columns: 0.1% 11% 27% 11% 27% 10%; SETTING FOR ODDS HISTORY CHART */
  grid-template-columns: 0.1% 11% 38% 11% 38% 0%;
  /* grid-template-rows: 3em; */
  grid-template-rows: 3.2em;
  margin: 0.15em 0 0 0;
}

.market-box-2-cols-tv {
  display: grid;
  width: 100%;
  /* grid-template-columns: 0.1% 11% 27% 11% 27% 10%; SETTING FOR ODDS HISTORY CHART */
  grid-template-columns: 0.1% 11% 38% 11% 38% 0%;
  grid-template-rows: 3em;
}

.market-box-2-hc-cols {
  display: grid;
  width: 100%;
  /* grid-template-columns: 0.1% 15% 11% 25% 11% 25% 10%; SETTING FOR ODDS HISTORY CHART */
  grid-template-columns: 0.1% 15% 11% 30% 11% 30% 0%;
  /* grid-template-rows: 3em; */
  grid-template-rows: 3.2em;
  margin: 0.15em 0 0 0;
}

.market-box-2-hc-cols-tv {
  display: grid;
  width: 100%;
  /* grid-template-columns: 0.1% 15% 11% 25% 11% 25% 10%; SETTING FOR ODDS HISTORY CHART */
  grid-template-columns: 0.1% 15% 11% 30% 11% 30% 0%;
  grid-template-rows: 2.6em;
}

.market-box-3-hc-cols {
  display: grid;
  width: 100%;
  /* grid-template-columns: 0.1% 9% 11% 15% 11% 15% 11% 15% 10%; SETTING FOR ODDS HISTORY CHART */
  grid-template-columns: 0.1% 9% 11% 18% 11% 18% 11% 18% 0%;
  /* grid-template-rows: 3em; */
  grid-template-rows: 3.2em;
  margin: 0.15em 0 0 0;
}

.market-box-3-hc-cols-tv {
  display: grid;
  width: 100%;
  /* grid-template-columns: 0.1% 9% 11% 15% 11% 15% 11% 15% 10%; SETTING FOR ODDS HISTORY CHART */
  grid-template-columns: 0.1% 9% 11% 18% 11% 18% 11% 18% 0%;
  grid-template-rows: 2.6em;
}


.font-odds-4-cols {
  font-size: 0.85em !important;
}

.grid-template-row-4-cols {
  grid-template-rows: 3.5em;
  ;
}

.market-box-horizontal {
  display: grid;
  width: 100%;
  grid-template-columns: 3% 25% 13% 11% 13% 11% 13% 11%;
  grid-template-columns: 0.1% 11% 18% 11% 18% 11% 18% 10%;
  /* grid-template-rows: 3.5em; */
  /* grid-row-gap: 0.1em; */
  /* background-color: var(--bg-primary); */
  /* padding-bottom: 0.1em; */
}

.market-box-vertical {
  display: grid;
  width: 100%;
  /* grid-template-columns: 26% 8% 7% 12% 7% 12% 7% 12% 9%; SETTING FOR ODDS HISTORY CHART */
  grid-template-columns: 25% 8% 7% 15% 7% 15% 7% 15% 0%;
  grid-template-rows: 3.5em;
  /* grid-row-gap: 0.1em; */
  background-color: var(--bg-primary);
  /* padding-bottom: 1px; */
  color: var(--color-markets);
  padding: 0.3em 0;
}

.market-box-vertical-910 {
  display: grid;
  width: 100%;
  /* grid-template-columns: 26% 8% 7% 12% 7% 12% 7% 12% 9%; SETTING FOR ODDS HISTORY CHART */
  grid-template-columns: 25% 8% 18% 17% 15% 17%;
  grid-row-gap: 6px;
  grid-template-rows: 3.5em;
  /* grid-row-gap: 0.1em; */
  background-color: var(--bg-primary);
  padding-bottom: 1px;
  color: var(--color-markets);
  margin: 0.25em 0;
}

.market-box-sidebets-separation {
  margin: 1.5em 0 0 0;
}

.price-border {
  /* margin-right: 1px; */
  margin-right: 0.3em;
}

.selection-label {
  padding-left: 0.3em;
  font-weight: normal;
}

.odds-charts {
  background-color: var(--bg-odds);
  margin: 0 0 0 1px;
  cursor: pointer;
  /* color: var(--color-contrast); */
}

.odds-btn {
  border-top: 1px solid var(--border-odds);
  border-bottom: 1px solid var(--border-odds);
  border-right: 1px solid var(--border-odds);
  background-color: var(--bg-odds);
  color: var(--color-odds);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.odds-btn-outright {
  border-top: 1px solid var(--border-odds);
  border-bottom: 1px solid var(--border-odds);
  border-right: 1px solid var(--border-odds);
  background-color: var(--bg-odds);
  color: var(--color-odds);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.markets-btn {
  border-top: 1px solid var(--border-odds);
  border-bottom: 1px solid var(--border-odds);
  border-left: 1px solid var(--border-odds);
  background-color: var(--bg-odds);
  color: var(--color-markets);
  /* font-size: 1em !important; */
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.selected-odds-left {
  border-top: 1px solid var(--border-odds-selected);
  border-bottom: 1px solid var(--border-odds-selected);
  border-left: 1px solid var(--border-odds-selected);
  color: var(--color-odds-selected) !important;
  background-color: var(--bg-odds-selected)
}

.selected-odds-right {
  border-top: 1px solid var(--border-odds-selected);
  border-bottom: 1px solid var(--border-odds-selected);
  border-right: 1px solid var(--border-odds-selected);
  color: var(--color-odds-selected);
  background-color: var(--bg-odds-selected);
}

.selected-odds-left {
  /* color: var(--color-odds-selected) !important; */
  color: var(--text-odd-selected) !important;
  /* background-color: var(--bg-odds-selected); */
  background-color: var(--color-contrast)
}

.selected-odds-right {
  /* color: var(--color-odds-selected); */
  color: var(--text-odd-selected);
  /* background-color: var(--bg-odds-selected); */
  background-color: var(--color-contrast)
}

.selected-odds {
  border: 1px solid var(--border-odds-selected);
  /* color: var(--color-odds-selected); */
  color: var(--color-odds-selected) !important;
  /* background-color: var(--bg-odds-selected); */
  color: var(--text-odd-selected) !important;
  background-color: var(--color-contrast)
}

.odds-featured {
  border: 1px solid var(--border-odds-featured);
}

.odds-charts-selected {
  background-color: var(--grey-7);
  color: var(--color-contrast);
}

.playerprops-market {
  display: flex;
  align-items: center;
  margin: 0 0 0 0;
}

.odds-raise {
  animation: highlightGreen 5s;
}

.odds-drop {
  animation: highlightRed 5s;
}

/* .odds-drop-label {
  animation: highlightRedLabel 5s;
} */

@keyframes highlightGreen {
  15% {
    color: var(--green);
    /* border-color: var(--border-odds-raise); */
  }

  85% {
    color: var(--green);
    /* border-color: var(--border-odds-raise); */
  }
}

@keyframes highlightRed {
  15% {
    color: var(--red);
    /* border-color: var(--border-odds-drop); */
  }

  85% {
    color: var(--red);
    /* border-color: var(--border-odds-drop); */
  }
}

.odds-raise-arrow {
  animation: arrowUp 5s;
}

.odds-drop-arrow {
  animation: arrowDown 5s;
}


@keyframes arrowUp {
  0% {
    background-repeat: no-repeat;
    background-position: right top;
  }

  15% {
    color: var(--green);
    /* border-color: var(--border-odds-raise); */
    /* background-image: var(--arrow-odds-raise); */
    background-repeat: no-repeat;
    background-position: right top;
  }

  85% {
    color: var(--green);
    /* border-color: var(--border-odds-raise); */
    /* background-image: var(--arrow-odds-raise); */
    background-repeat: no-repeat;
    background-position: right top;
  }

  100% {
    background-repeat: no-repeat;
    background-position: right top;
  }
}

@keyframes arrowDown {
  0% {
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  15% {
    color: var(--red);
    /* border-color: var(--border-odds-drop); */
    /* background-image: var(--arrow-odds-drop); */
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  85% {
    color: var(--red);
    /* border-color: var(--border-odds-drop); */
    /* background-image: var(--arrow-odds-drop); */
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  100% {
    background-repeat: no-repeat;
    background-position: right bottom;
  }
}



/* MEDIAQUERIES */

@media (hover: hover) {
  .market-box-vertical {
    grid-template-rows: 3em;
  }

  /* .market-box-vertical:nth-child(1) {
    background-color: #000000;
  } */
  .market-box-horizontal {
    grid-template-rows: 3.2em;
  }

  /* .market-box-horizontal:hover {
    background-color: var(--color-main); 
  } */
  .odds-btn:hover {
    background-image: var(--odd-hover);
    /* color: var(--color-main) !important; */
  }

  .odds-btn-outright:hover {
    background-image: var(--odd-hover);
    /* color: var(--color-main) !important; */
  }

  .odds-charts:hover {
    background-image: var(--odd-hover);
  }

  .price-border {
    margin-right: 0.5em;
  }

  .odds-charts {
    font-size: 1.3em;
  }

  .odds-charts-selected {
    font-size: 1.3em;
  }

  .selection-label {
    margin-right: -0.1em;
  }

  .markets-intend {
    margin: 0 0 0 3.1em;
  }

  .market-box-vertical:hover {
    background: var(--bg-marketline-hover);
  }

  .marketset-grid-4-cols-goalscorer:hover {
    background: var(--bg-marketline-hover);
  }

  .playerprops-market {
    margin: 0 0 0 40%;
  }
}