.bluedark {
  /*colors */
  --error-color: #bb1956;
  --color-contrast: #3da3fd;
  /* --color-contrast: #ffffff; */
  --color-contrast-1: #3894e5;
  --color-contrast-2: #7bb4dd;
  --color-contrast-3: #1d426a;
  --color-contrast-4: ;
  --color-contrast-5: #3da3fd;
  /* --color-main: #0d0f14; */
  --color-main: #191e2d;
  --color-ripple: #3da3fd;
  --green: #15a000;
  --green-1: #10609d;
  --green-2: #1b3a2b;
  --grey: #ffffff;
  --grey-1: #2c343a;
  --grey-2: #36363a;
  --grey-3: #8a9096;
  --grey-4: #444444;
  --grey-5: #2f3b47;
  --grey-6: #2d2d30;
  --grey-7: #0d0f14;
  --grey-8: #2d2d30;
  --grey-9: #151515;
  --grey-10: #7e868a;
  --grey-11: #8a9096;
  --grey-13: #cfcfcf;
  --grey-14: #13171b;
  --grey-15: #999b9d;
  --grey-17: #434345;
  --grey-18: #21282f;
  --grey-19: #272935;
  --red: #e80f0f;
  --red-1: #cf5081;
  --red-2: #551616;
  --trans-1: #ffffffb3;
  --trans-2: #00000073;
  --white: #ffffff;
  --black: #000000;
  --yellow: #e9ff00;
  /* --yellow-1: #f3c814; */
  --yellow-1: #eac918;
  /* --odd-hover: linear-gradient(to right, rgba(0, 0, 0, 0), #f3c81430); */
  --odd-hover: #ffffff;
  --odd-featured-hover: linear-gradient(to right, rgba(0, 0, 0, 0), #f3c81400, #f3c81430);
  --odd-hover-outright: #ffffff0b;
  --title-game-gradient: #191e2d00;
  --title-game-gradient-conference: linear-gradient(to right, #25242ed3, #75757571);
  --box-gradient: #0d0f14;
  --box-margin: 1px;
  --bg-tabs-1: #191e2d;
  --bg-tabs-2: #191e2d;
  --bg-tabs-2: #21283c;
  /* --border-gamebox: #272e45; */
  --border-gamebox: #191e2d;
  --border-odds-drop: #00000000;
  --border-odds-raise: #00000000;
  --arrow-odds-drop: none;
  --arrow-odds-raise: none;
  --bg-primary: #191e2d;
  --bg-secondary: #242327;
  --bg-odds: #21283c;
  --bg-odds-banner: #312c11;
  --border-odds: #00000000;
  --bg-odds-selected: #21283c;
  --border-odds-featured: #ffffff00;
  --border-odds-selected: #3da3fd;
  /* --color-odds: #f3c814; */
  --color-odds: #ffffff;
  --color-markets: #cfcfcf;
  --color-odds-selected: #f3c814;
  --color-time: #999999;
  /* --bg-stake: #1e2436; */
  --bg-stake: #21283c;
  --bg-promo: #1e2436;
  --bg-promo: #20283c;
  --bg-league-box: #191e2d;
  --bg-league-box-selected: #191e2d;
  /* --color-league-box-selected: #3da3fd; */
  --color-league-box-selected: #ffffff;
  --bg-league-box-hover: #191e2d;
  --bg-app: #191e2d;
  --hover-casino: #313131d2;
  --color-contrast-slip: #a8b9d6;
  /* --color-selection-slip: #eac918; */
  /* --color-selection-slip: #ffffff; */
  --color-selection-slip: #999b9d;
  /* --bg-results: #0d0f14; */
  --bg-results: #21283c;
  --text-results: #cfcfcf;
  --bg-teams-sidebets-left: linear-gradient(to left, rgb(42 118 221 / 0%), #0e2452);
  --bg-teams-sidebets-right: linear-gradient(to right, rgb(42 118 221 / 0%), #0e2452);
  --btn-login: #000000;
  --btn-slip: #000000;
  /* --btn-text: #ffffff; */
  --btn-text: #ffffff;
  /* --bg-btn: linear-gradient(0deg, rgba(29, 88, 154, 1) 0%, rgba(61, 163, 253, 1) 50%, rgba(29, 88, 154, 1) 100%); */
  --bg-search-input: #445775;
  --bg-slip: #21283c;
  /* --bg-slip-selection: #191e2d; */
  --bg-slip-selection: #21283c;
  --bg-slip-top: #191e2d;
  --color-slip-top: #3da3fd;
  --color-market-tabs: #cfcfcf;
  --bg-menu-top: #191e2d;
  --bg-leagues-filter-tabs: #191e2d;
  --bg-promo-banner: #191e2d;
  --banklogin: #fbb217;
  --banklogin-hover: #da9b13;
  /* --btn-banklogin: linear-gradient(180deg, #fbb217 0%, rgb(124, 95, 52) 25%, rgb(129, 88, 38) 50%, rgb(136, 103, 54) 75%, #fbb217 100%); */
  --btn-banklogin: #3399ff;
  --bg-btn-login: linear-gradient(180deg, rgba(214, 214, 214, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(214, 214, 214, 1) 100%);
  --color-time-live: #3da3fd;
  --color-headline: #cfcfcf;
  --color-input-amount: #ffffff;
  --btn-banklogin-text: #ffffff;
  --bg-bet-accepted: #171b28d2;
  --color-sportstabs: #c9c9c9;
  --bg-marketline-hover: #ffffff02;
  --color-selection-label: #959595;
  --color-max-bet-limit: #ffffff;
  /* --border-slip: #1f2436; */
  --border-slip: #191e2d;
  --color-stake: #ffffff;
  --color-team: #e9e9e9;
  --color-slip-tabs: #3da3fd;
  --color-tr: #ffffff;
  --bg-slip-switch: #0f83d6cb;
  --bg-slip-switch-hover: #1164c2cb;
  --color-maintenance: #ffffff;
  --color-time-mobile: #999999;
  --color-hc: #ffffff;
  --color-icon-1: #ffffff;
  --color-icon-2: #8e8e8e;
  --color-pulse-1: #3da3fd;
  --color-pulse-2: #ffffff;
  --nonrunner: #3f4c5f;
  --bg-sports-tabs-selected: #191e2d;
  --color-sports-tabs-selected: #3da3fd;
  --bg-casino-search: #445775;
  --bg-casino-search-2: #212538;
  --btn-more: #facc00;
  --color-infobox: #facc00;
  --color-vs: #f3c61490;
  --color-promo-badge: #1f2436;
  --color-balance: #ffffff;
  /* --bg-betbuilder-game: #1e2436; */
  --bg-betbuilder-game: #20283c;
  --border-banner-btn: #445775;
  --bg-featured-banner: #060609;
  --bg-footer: #1e2436;
  /* --btn-provider: linear-gradient(0deg, rgb(29, 88, 154) 0%, rgb(61, 163, 253) 50%, rgb(29, 88, 154) 100%); */
  --btn-provider-2: #212538;
  --casino-provider: invert(0) brightness(80%);
  --db-primary-color: #191e2d;
  --db-primary-text: #ffffff;
  --db-primary-accent: #3da3fd;
  --db-secondary-color: #21283c;
  --db-secondary-text: #a4a4a4;
  --db-warning-color: #3da3fd;
  --text-odd-selected: #ffffff;
  --color-sportstabs: #ffffff;
  --color-menu-more: #191e2dfc;
  --color-main-featured: #999b9d;
  --bg-odds-featured: #2e3445;
  /* --bet-font-size-base: 16px !important; */
  /* --bet-primary-text: #f3c814 !important; */


    /*fonts*/
    --letter-spacing: 0px;
  --content: calc(var(--szf) / 3.5);
  /*10px;*/
  --small: calc(var(--szf) / 3.7);
  /*10px;*/
  --normal: calc(var(--szf) / 2.9);
  /*11px; war 3.33*/
  --mmedium: calc(var(--szf) / 3);
  /*12px;*/
  --smedium: calc(var(--szf) / 3.3);
  --large: calc(var(--szf) / 2.77);
  /*13px;*/
  --xlarge: calc(var(--szf) / 2.5);
  /*14px;*/
  --xxlarge: calc(var(--szf) / 1.2);
  /*16px;*/
  --h1: calc(var(--szf) / 1.4);
  --h2: calc(var(--szf) / 1.2);
  --h3: calc(var(--szf) / 1.2);
  --h4: calc(var(--szf) / 1.2);
  --h7: calc(var(--szf) / 3);

  /*shifter*/
  --sz: 10vw;
  --szf: 10vw;
  --szf: 11.5vw;

  /*images*/
  --bg-sportsbetting: url(../../media/img/bg_sportsbetting_bluedark.jpg);
}


.bluelight {
  /*colors*/
  --error-color: #bb1956;
  --color-contrast: #338bdd;
  --color-contrast-1: #0ba274;
  --color-contrast-2: #7bddba;
  --color-contrast-3: #aeedd8;
  --color-contrast-4: #26bd86;
  --color-contrast-5: #338bdd;
  --color-main: #ffffff;
  --color-ripple: #dcebf9;
  --green: #04c327;
  --green-1: #5eb194;
  --green-2: #e5f4ef;
  --grey: #2e2e2e;
  --grey-1: #b5b9bb;
  --grey-2: #eaeaea;
  --grey-3: #b5b9bb;
  --grey-4: #d8d8d8;
  --grey-5: #d7d7d7;
  --grey-6: #ffffff;
  --grey-7: #ffffff;
  --grey-8: #ededee;
  --grey-9: #ecebeb;
  --grey-10: #7e868a;
  --grey-11: #8a9096;
  --grey-13: #808080;
  --grey-14: #d8d8d8;
  --grey-15: #999b9d;
  --grey-17: #dfdfdf;
  --grey-18: #e9e9e9;
  --grey-19: #e9e9e9;
  --red: #e80f0f;
  --red-1: #cf5081;
  --red-2: #f9e6ec;
  --trans-1: #ffffffb3;
  --trans-2: #00000073;
  --white: #ffffff;
  --black: #000000;
  --yellow: #ffee00;
  --yellow-1: #f3c814;
  --odd-hover: none;
  --odd-hover-outright: #ffffff0b;
  --title-game-gradient: #ffffff00;
  --title-game-gradient-conference: linear-gradient(to right, #ffffffd3, #e9e9e971);
  --box-gradient: transparent;
  --box-margin: 0;
  --bg-tabs-1: #ffffff;
  --bg-tabs-2: #ffffff;
  --bg-tabs-2: #f0f0f0;
  --border-gamebox: #ffffff;
  --border-odds-drop: #f0f0f0;
  --border-odds-raise: #f0f0f0;
  --bg-primary: #ffffff;
  --bg-secondary: #dddddd;
  --bg-odds: #f0f0f0;
  --bg-odds-banner: #f0f0f0;
  --border-odds: #f0f0f0;
  --bg-odds-selected: #338bdd;
  --border-odds-featured: #ffffff00;
  --border-odds-selected: #f0f0f0;
  --color-odds: #181d2a;
  --color-markets: #8b8b8b;
  --color-odds-selected: #ffffff;
  --color-time: #8b8b8b;
  --bg-stake: #f0f0f0;
  --bg-promo: #fafafa;
  --bg-promo: #f0f0f0;
  --bg-league-box: #ffffff;
  --bg-league-box-selected: #ffffff;
  --color-league-box-selected: #181d2a;
  --bg-league-box-hover: #f9f9f9;
  --bg-app: #ffffff;
  --hover-casino: radial-gradient(#cececec4, #cececec4, #ffffff);
  --color-contrast-slip: #5a6b8b;
  --color-selection-slip: #000000;
  --bg-results: #f0f0f0;
  --text-results: #338bdd;
  --bg-teams-sidebets-left: linear-gradient(to left, rgb(42 118 221 / 0%), #0e2452);
  --bg-teams-sidebets-right: linear-gradient(to right, rgb(42 118 221 / 0%), #0e2452);
  --btn-login: #8b8b8b;
  --btn-slip: #ffffff;
  --btn-text: #ffffff;
  --bg-search-input: #e6e6e6;
  --bg-slip: #f0f0f0;
  --bg-slip-selection: #f0f0f0;
  --bg-slip-top: #ffffff;
  --color-slip-top: #338bdd;
  --color-market-tabs: #8b8b8b;
  --bg-menu-top: #ffffff;
  --bg-leagues-filter-tabs: #ffffff;
  --bg-promo-banner: #ffffff;
  --banklogin: #fbb217;
  --banklogin-hover: #da9b13;
  --btn-banklogin: linear-gradient(180deg, #fbb217 0%, rgb(223, 163, 42) 50%, #fbb217 100%);
  --bg-btn-login: linear-gradient(180deg, rgb(175, 175, 175) 0%, rgb(68, 68, 68) 50%, rgb(84, 84, 84) 100%);
  --color-time-live: #338bdd;
  --color-headline: #cfcfcf;
  --color-input-amount: #8b8b8b;
  --btn-banklogin-text: #ffffff;
  --bg-bet-accepted: #f0f0f0d2;
  --color-sportstabs: #338bdd;
  --bg-marketline-hover: #fcfcfc;
  --color-selection-label: #959595;
  --color-max-bet-limit: #ff8c00;
  --border-slip: #ffffff;
  --color-stake: #181d2a;
  --color-team: #6b6b6b;
  --color-slip-tabs: #338bdd;
  --color-tr: #ffffff;
  --bg-slip-switch: #0e2d6dcb;
  --bg-slip-switch-hover: #002f1dcb;
  --color-maintenance: #0e2d6d;
  --color-time-mobile: #999999;
  --color-hc: #8e8e8e;
  --color-icon-1: #a9a9a9;
  --color-icon-2: #585757;
  --color-pulse-1: #e3e3e3;
  --color-pulse-2: #ffffff;
  --nonrunner: #3f5f56;
  --bg-sports-tabs-selected: #ffffff;
  --color-sports-tabs-selected: #338bdd;
  --bg-casino-search: #f0f0f0;
  --bg-casino-search-2: #f0f0f0;
  --color-infobox: #facc00;
  --color-vs: #f3c61490;
  --color-promo-badge: #1f2436;
  --color-balance: #1f2436;
  --bg-betbuilder-game: #f0f0f0;
  --border-banner-btn: #e6e6e6;
  --bg-featured-banner: #f2f2f2;
  --bg-footer: #fafafa;
  --btn-provider-2: #f0f0f0;
  --casino-provider: invert(1) brightness(50%);
  --db-primary-color: #ffffff;
  --db-primary-text: #2e2e2e;
  --db-primary-accent: #338bdd;
  --db-secondary-color: #f0f0f0;
  --db-secondary-text: #6b6b6b;
  --db-warning-color: #3da3fd;
  --text-odd-selected: #ffffff;
  --color-sportstabs: #181d2a;
  --color-menu-more: #ffffffe3;
  --color-main-featured: #000000;
  --bg-odds-featured: #dddddd;
  
  /*fonts*/
  --letter-spacing: 0px;
  --content: calc(var(--szf) / 3.5);
  /*10px;*/
  --small: calc(var(--szf) / 3.7);
  /*10px;*/
  --normal: calc(var(--szf) / 2.9);
  /*11px; war 3.33*/
  --mmedium: calc(var(--szf) / 3);
  /*12px;*/
  --smedium: calc(var(--szf) / 3.3);
  --large: calc(var(--szf) / 2.77);
  /*13px;*/
  --xlarge: calc(var(--szf) / 2.5);
  /*14px;*/
  --xxlarge: calc(var(--szf) / 1.2);
  /*16px;*/
  --h1: calc(var(--szf) / 1.4);
  --h2: calc(var(--szf) / 1.2);
  --h3: calc(var(--szf) / 1.2);
  --h4: calc(var(--szf) / 1.2);
  --h7: calc(var(--szf) / 3);

  /*shifter*/
  --sz: 10vw;
  --szf: 10vw;
  --szf: 11.5vw;

  /*images*/
  --bg-sportsbetting: url(../../media/img/bg_sportsbetting_bluedark.jpg);
}

.tv {
  /*colors */
  --error-color: #bb1956;
  --color-contrast: #0fd697;
  --color-contrast-1: #0ba274;
  --color-contrast-2: #7bddba;
  --color-contrast-3: #1d6a50;
  --color-contrast-4: ;
  --color-main: #0d1412;
  --color-ripple: #4e947b;
  --green: #15a000;
  --green-1: #5eb194;
  --green-2: #1b3a2b;
  --grey: #afafaf;
  --grey-1: #2c343a;
  --grey-2: #36363a;
  --grey-3: #8a9096;
  --grey-4: #444444;
  --grey-5: #2f4740;
  --grey-6: #2d2d30;
  --grey-7: #0d1412;
  --grey-8: #2d2d30;
  --grey-9: #151515;
  --grey-10: #7e868a;
  --grey-11: #8a9096;
  --grey-13: #cfcfcf;
  --grey-14: #13171b;
  --grey-15: #999b9d;
  --grey-17: #434345;
  --grey-18: #21282f;
  --red: #e80f0f;
  --red-1: #cf5081;
  --red-2: #551616;
  --trans-1: #ffffffb3;
  --trans-2: #00000073;
  --white: #ffffff;
  --black: #000000;
  --yellow: #e9ff00;
  --yellow-1: #f3c814;
  --odd-hover: linear-gradient(to right, rgba(0, 0, 0, 0), #42cd9130);
  --title-game-gradient: #0d1412;
  --title-game-gradient-conference: linear-gradient(to right, #25242ed3, #75757571);
  --box-gradient: #0d1412;
  --box-margin: 1px;
  --bg-tabs-1: #172923;
  --bg-tabs-2: #0d1412;
  --border-gamebox: #1f362e;
  --border-odds-drop: #00000000;
  --border-odds-raise: #00000000;
  --arrow-odds-drop: none;
  --arrow-odds-raise: none;
  --bg-primary: #0d1412;
  --bg-secondary: #242327;
  --bg-odds: #172822;
  --border-odds: #00000000;
  --bg-odds-selected: #0d1412;
  --border-odds-selected: #0fd697;
  --color-odds: #ffffff;
  --color-markets: #cfcfcf;
  --color-odds-selected: #0fd697;
  --color-time: #999999;
  --bg-stake: #172a22;
  --bg-league-box: #0d1412;
  --bg-league-box-selected: #172822;
  --bg-league-box-hover: #172822;
  --bg-app: #0d1412;
  --hover-casino: #313131d2;
  --color-contrast-slip: #7ca99a;
  --bg-results: #222222;
  --text-results: #cfcfcf;
  --bg-teams-sidebets-left: linear-gradient(to left, rgb(42 118 221 / 0%), #096d4d);
  --bg-teams-sidebets-right: linear-gradient(to right, rgb(42 118 221 / 0%), #096d4d);
  --btn-login: #000000;
  --btn-slip: #000000;
  --btn-text: #000000;
  --bg-search-input: #0a0a0a;
  --bg-slip-selection: #141514;
  --bg-slip-top: #172922;
  --color-slip-top: #cfcfcf;
  --color-market-tabs: #cfcfcf;
  --bg-menu-top: #172923;
  --banklogin: #fbb217;
  --banklogin-hover: #da9b13;
  --btn-banklogin: linear-gradient(180deg, #fbb217 0%, rgb(124, 95, 52) 25%, rgb(129, 88, 38) 50%, rgb(136, 103, 54) 75%, #fbb217 100%);
  --bg-btn-login: linear-gradient(180deg, rgba(214, 214, 214, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(214, 214, 214, 1) 100%);
  --color-time-live: #0fd697;
  --color-headline: #cfcfcf;
  --color-input-amount: #ffffff;
  --btn-banklogin-text: #ffffff;
  --bg-bet-accepted: #172822d2;
  --color-sportstabs: #c9c9c9;
  --bg-marketline-hover: linear-gradient(-90deg, rgba(0, 0, 0, 1) 0%, rgba(23, 40, 34, 1) 100%);
  --color-selection-label: #959595;
  --color-max-bet-limit: #ffffff;
  --border-slip: #1f362e;
  --color-stake: #ffffff;
  --color-team: #e9e9e9;
  --color-slip-tabs: #0fd697;
  --color-tr: #ffffff;
  --bg-slip-switch: #0fd697cb;
  --bg-slip-switch-hover: #11c28acb;
  --color-maintenance: #ffffff;
  --color-time-mobile: #999999;
  --color-hc: #ffffff;
  --color-icon-1: #ffffff;
  --color-icon-2: #8e8e8e;

  /*fonts*/
  --letter-spacing: 0px;
  --content: calc(var(--szf) / 3.5);
  /*10px;*/
  --small: calc(var(--szf) / 3.7);
  /*10px;*/
  --normal: calc(var(--szf) / 2.9);
  /*11px; war 3.33*/
  --mmedium: calc(var(--szf) / 3);
  /*12px;*/
  --smedium: calc(var(--szf) / 3.3);
  --large: calc(var(--szf) / 2.77);
  /*13px;*/
  --xlarge: calc(var(--szf) / 2.5);
  /*14px;*/
  --xxlarge: calc(var(--szf) / 1.2);
  /*16px;*/
  --h1: calc(var(--szf) / 1.4);
  --h2: calc(var(--szf) / 1.2);
  --h3: calc(var(--szf) / 1.2);
  --h4: calc(var(--szf) / 1.2);
  --h7: calc(var(--szf) / 3);

  /*shifter*/
  --sz: 10vw;
  --szf: 10vw;

  /*images*/
  --bg-sportsbetting: url(../../media/img/bg_sportsbetting.jpg);
}


/* MEDIAQUERIES */

/* WEBVERSION */

@media (hover: hover) {
  .bg-web-img {
    /* background-image: var(--bg-sportsbetting);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed; */
    min-height: 100vh;
  }

  /* .green-button:hover {
    background-color: var(--green-1) !important;
  } */

  .gray-button:hover {
    background-color: var(--red) !important;
    color: var(--white) !important;
  }

  .red-button:hover {
    background-color: var(--red-1) !important;
  }

  .contrast-button:hover {
    background-color: var(--color-contrast-2) !important;
  }

  h6 {
    margin: 0.6em 0;
    /* border-bottom: 1px solid var(--grey-1); */
  }

  .spacer-left-list {
    padding-left: 1em;
  }

  .spacer-left-list-tv {
    padding-left: 0.8em;
  }

  /* .spacer-right {
    padding-left: 1em;
  } */
}

@media (hover: hover) and (min-width: 481px) {
  :root {
    --szf: 2.75vw;
  }

  .font-selection-label {
    font-size: 0.8em;
    color: var(--grey);
  }

  .font-xs {
    font-size: 0.8em;
  }

  .font-s {
    font-size: 1.2em;
  }

  .font-m {
    font-size: 1.2em;
  }

  .font-l {
    font-size: 1.2em;
  }

  .font-xl {
    font-size: 1.2em;
  }

  .font-xxl {
    font-size: 1.4em;
  }

  .font-xxxl {
    font-size: 1.5em;
  }

  .font-xxxxl {
    font-size: 1.6em;
  }

  .font-xxxxxl {
    font-size: 2.3em;
  }

  /* .font-time {
    font-size: 1.1em;
  } */

  .font-hc {
    font-size: 0.9em;
  }


}

/* MOBILE */

@media (hover: none) and (min-width: 600px) and (max-width:1023px) and (orientation: portrait) {

  :root {
    --szf: 7vw;
  }

  .MuiBottomNavigationAction-label {
    font-size: 1.2rem !important;
  }

  .MuiBottomNavigationAction-label.Mui-selected {
    font-size: 1.2rem !important;
  }

  .font-xl {
    font-size: 0.8em;
  }

  .font-time-1 {
    font-size: 0.7em;
  }

  .font-time {
    font-size: 0.7em;
  }

  .font-selection-label {
    font-size: 0.6em;
    color: var(--grey);
  }

  .font-hc {
    font-size: 0.6em;
  }

  .font-title-outright {
    font-size: 0.8em;
  }

  .font-team {
    font-size: 0.8em;
  }

  .font-detail-1 {
    font-size: 0.8em;
  }

}


@media (hover: none) and (min-width: 1024px) and (orientation: portrait) {
  :root {
    --szf: 5.5vw;
  }

  .MuiBottomNavigationAction-label {
    font-size: 1.2rem !important;
  }

  .MuiBottomNavigationAction-label.Mui-selected {
    font-size: 1.2rem !important;
  }

  .font-xl {
    font-size: 0.9em;
  }

  .font-time-1 {
    font-size: 0.7em;
  }

  .font-selection-label {
    font-size: 0.6em;
    color: var(--grey);
  }

  .font-hc {
    font-size: 0.6em;
  }
}

/* MOBILE LANDSCAPE */

@media screen and (hover: none) and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  :root {
    --szf: 9vH;
  }

  .MuiBottomNavigationAction-label {
    font-size: 0.6rem !important;
  }

  .MuiBottomNavigationAction-label.Mui-selected {
    font-size: 0.6rem !important;
  }

  .MuiBottomNavigation-root {
    height: 45px !important;
  }
}

@media screen and (hover: none) and (min-width: 768px) and (max-width: 813px) and (orientation: landscape) {
  :root {
    --szf: 11vH;
  }

  .MuiBottomNavigationAction-label {
    font-size: 0.6rem !important;
  }

  .MuiBottomNavigationAction-label.Mui-selected {
    font-size: 0.6rem !important;
  }

  .MuiBottomNavigation-root {
    height: 45px !important;
  }
}

@media screen and (hover: none) and (min-width: 814px) and (max-width: 1023px) and (orientation: landscape) {
  :root {
    --szf: 6vH;
  }

  .MuiBottomNavigationAction-label {
    font-size: 0.8rem !important;
  }

  .MuiBottomNavigationAction-label.Mui-selected {
    font-size: 0.8rem !important;
  }
}

@media screen and (hover: none) and (min-width: 1024px) and (orientation: landscape) {
  :root {
    --szf: 4vH;
  }

  .MuiBottomNavigationAction-label {
    font-size: 0.8rem !important;
  }

  .MuiBottomNavigationAction-label.Mui-selected {
    font-size: 0.8rem !important;
  }
}

/* TV SCREEN OVERWRITE*/

.tv-screen {

  /*colors*/
  /*colors */
  --error-color: #bb1956;
  --color-contrast: #0fd697;
  --color-contrast-1: #0ba274;
  --color-contrast-2: #7bddba;
  --color-contrast-3: #1d6a50;
  --color-contrast-4: ;
  --color-main: #000000;
  --color-ripple: #4e947b;
  --green: #2b9c75;
  --green-1: #5eb194;
  --green-2: #1b3a2b;
  --grey: #afafaf;
  --grey-1: #2c343a;
  --grey-2: #36363a;
  --grey-3: #8a9096;
  --grey-4: #444444;
  --grey-5: #303030;
  --grey-6: #2d2d30;
  --grey-7: #000000;
  --grey-8: #2d2d30;
  --grey-9: #2e2e2e;
  --grey-9: #151515;
  --grey-10: #7e868a;
  --grey-11: #8a9096;
  --grey-13: #cfcfcf;
  --grey-14: #13171b;
  --grey-15: #999b9d;
  --grey-17: #434345;
  --grey-18: #21282f;
  --red: #bb1956;
  --red-1: #cf5081;
  --red-2: #551616;
  --trans-1: #ffffffb3;
  --trans-2: #00000073;
  --white: #ffffff;
  --black: #000000;
  --yellow: #d6bc08;
  --yellow-1: #f3c814;
  --odd-hover: linear-gradient(to right, rgb(255 255 255 / 0%), rgb(255 255 255 / 10%));
  --odd-hover: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(157, 157, 157, 0.1));
  --title-game-gradient: linear-gradient(to right, #272d3a, #1a1f27 70%);
  --title-game-gradient: linear-gradient(to right, #272727, #141414 70%);
  --title-game-gradient: ;
  --title-game-gradient-conference: linear-gradient(to right, #25242ed3, #75757571);
  --box-gradient: linear-gradient(to right, #272d3a, #1a1f27 70%);
  --box-gradient: #000;
  --box-margin: 1px;
  --bg-tabs-1: #1a2025;
  --bg-tabs-1: #121212;
  --bg-tabs-2: linear-gradient(to right, #262c37, #1f242d);
  --bg-tabs-2: #000000;
  --border-gamebox: #141514;
  --border-odds-drop: #00000000;
  --border-odds-raise: #00000000;
  --arrow-odds-drop: none;
  --arrow-odds-raise: none;
  --bg-primary: #000000;
  --bg-secondary: #141514;
  --bg-odds: #141514;
  --border-odds: #00000000;
  --bg-odds-selected: #000;
  --border-odds-selected: #0fd697;
  --color-odds: #cfcfcf;
  --color-markets: #cfcfcf;
  --color-odds-selected: #0fd697;
  --color-time: #999999;
  --bg-stake: #121212;
  --bg-league-box: #000;
  --bg-league-box-selected: #141514;
  --bg-league-box-hover: #141514;
  --bg-app: #000000;
  --hover-casino: #313131d2;
  --color-contrast-slip: #929495;
  --bg-results: #222222;
  --text-results: #cfcfcf;
  --bg-teams-sidebets-left: linear-gradient(to left, rgb(42 118 221 / 0%), #0fd697);
  --bg-teams-sidebets-right: linear-gradient(to right, rgb(42 118 221 / 0%), #0fd697);

  /*fonts*/
  --letter-spacing: 0px;
  --content: calc(var(--szf) / 3.5);
  /*10px;*/
  --xsmall: calc(var(--szf) / 9);
  /*10px;*/
  --small: calc(var(--szf) / 2.5);
  /*10px;*/
  --normal: calc(var(--szf) / 2.9);
  /*11px; war 3.33*/
  --mmedium: calc(var(--szf) / 3);
  /*12px;*/
  --smedium: calc(var(--szf) / 3.3);
  --large: calc(var(--szf) / 2.77);
  /*13px;*/
  --xlarge: calc(var(--szf) / 2.5);
  /*14px;*/
  --xxlarge: calc(var(--szf) / 1.2);
  /*16px;*/
  --h1: calc(var(--szf) / 1.4);
  --h2: calc(var(--szf) / 1.2);
  --h3: calc(var(--szf) / 1.2);
  --h4: calc(var(--szf) / 1.2);
  --h7: calc(var(--szf) / 3);

  /*shifter*/
  --sz: 10vw;
  --szf: 10vw;

  /*images*/
  --bg-sportsbetting: none;
}