.stake-pad-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 33% 34% 33%;
  grid-template-rows: 2.5em 3.5em 3.5em 3.5em 3.5em 2.8em;
  grid-gap: 1px;
  overflow-x: hidden;
}

.stake-pad-grid div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stake-pad-button:active {
  opacity: 0.5;
}

.stake-pad-place-button {
  grid-column: 1 / 4;
}
