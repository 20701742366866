.ticket-selection {
    display: grid;
    width: 100%;
    grid-template-columns: 43% 10% 12% 5% 28%;
    grid-template-rows: auto auto;
    /* background-color: var(--grey-7); */
    border-bottom: 0.1em solid var(--border-gamebox);
  }

.ticket-selection-betbuilder {
    display: grid;
    width: 100%;
    grid-template-columns: 87% 10% 0% 0% 1%;
    grid-template-rows: auto auto;
    /* background-color: var(--grey-7); */
    border-bottom: 0.1em solid var(--border-gamebox);
    padding: 0.5em 0;
  }
  
  .grid-divider-system {
    grid-column: 1;
    grid-row: 1 / 3;
  }

  .tr-ticket-nonrunner{
    margin-left: 2px;
    position: relative;
    cursor: pointer;
  }

  .tr-ticket-nonrunner::after{
    content: "*"; 
    margin-left: 2px;
    color: var(--green);
  }

  .tr-nonrunner-info-box{
    position: absolute;
    background-color: black;
    width: 180px;
    padding: 10px;
    bottom: 0;
    border-radius: 4px;
    margin-top: 30px;
    z-index: 20;
    display: none;
  }

  .tr-nonrunner-info-box::after{
     content: "";
     width: 10px;
     height: 10px;
     background-color: black;
     position: absolute;
     bottom: 0;
     right: 0;
     margin-right: 15px;
     margin-top: -4px;
     transform: rotate(45deg);
  }

  .nonrunner-info-text{
    font-size: 1em;
  }

  .tr-ticket-nonrunner:hover .tr-nonrunner-info-box{
     display: block;
  }

  .tr-ticket-info-divider {
    grid-column: 1 / span 5;
  }
  .tr-ticket-info-box {
    padding: 1em;
    border: 1px solid var(--color-contrast-slip);
    border-radius: 0.5em;
  }

  /* MEDIAQUERIES */

@media (hover: none) and (min-width: 768px) and (max-width:1023px){
  .ticket-selection {
    grid-template-columns: 53% 6% 6% 5% 28%;
    text-align: center;
  }
}
@media (hover: none) and (min-width: 1024px){
  .ticket-selection {
    grid-template-columns: 53% 6% 6% 5% 28%;

  }
}

@media (hover: hover) {
  .ticket-selection {
    border-bottom: 0.1em solid var(--border-gamebox);
  }
}