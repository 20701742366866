.slip-selection {
  display: grid;
  width: 100%;
  grid-template-rows: auto 1.5em;
  background-color: var(--bg-slip-selection);
  /* border-bottom: 0.05em solid var(--border-slip); */
  grid-template-columns: 4.3em auto 2em 3.5em 2em 4.5em 3em;
}

.slip-selection-betbuilder {
  display: grid;
  width: 100%;
  grid-template-rows: auto auto;
  background-color: var(--bg-slip-selection);
  grid-template-columns: 0em auto 0em 3em 0.5em 5em 3em;
  padding: 0.5em 0 0 0;
}

.slip-selection-system {
  display: grid;
  width: 100%;
  grid-template-rows: auto 1.5em;
  background-color: var(--bg-slip-selection);
  /* border-bottom: 0.05em solid var(--border-slip); */
  grid-template-columns: 3.5em auto 0em 0.5em 2em 4.5em 3em;
  /* padding: 0.1em 0 0.1em 0; */
  padding: 0.5em 0 1em 0;
  padding: 0.3em 0 1em 0;
}

.slip-selection-system-betbuilder {
  display: grid;
  width: 100%;
  grid-template-rows: auto auto;
  background-color: var(--bg-slip-selection);
  /* border-bottom: 0.05em solid var(--border-slip); */
  grid-template-columns: 3.5em auto 0em 3em 0.5em 5em 3em;
  padding: 0.5em 0;

}

.slip-selection-divider {
  grid-row-start: span 2;
}

.grid-divider-system {
  grid-column: 1;
  grid-row: 1 / 3;
}

.grid-divider-place {
  grid-column: 1 / span 2;
  grid-row: second-line / 2;
}

.slip-odd-update {
  font-size: 1.5em !important;
}

.slip-odd-raise {
  font-size: 1.1em !important;
  display: flex !important;
}

.slip-odd-drop {
  font-size: 1.1em !important;
  display: flex !important;
}

.slip-odd-update {
  font-size: 1.5em !important;
}

/* .slip-bank-button {
    margin: 0.22rem 0.35rem;
} */
/* 
.slip-bank-button-hover {
    width: 1.5em;
    height: 1.5em;
    line-height: 1.4em;
    margin: 0.22em 0.4em;
} */

.slip-stake {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr 2fr;
  grid-template-rows: 4.5em;
  background-color: var(--bg-stake);
}

.slip-stake-betbuilder {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 4.5em;
  background-color: var(--bg-stake);
}

.slip-stake-system {
  display: grid;
  width: 100%;
  grid-template-columns: 3.5em 8em auto 10em;
  grid-template-rows: 1.5em 1.5em;
  background-color: var(--bg-stake);
  
}

.system-spacer {
  padding-top: 5px;
}


.stake-system-select {
  grid-column: 1;
  grid-row: 1 / 3;
}

.stake-system-stake {
  grid-column: 4;
  grid-row: 1 / 3;
}

.slip-max-button>.MuiIconButton-root {
  padding: 0 !important;
  width: 1em;
}

.slip-odd-changes-symbol>.MuiSvgIcon-root {
  font-size: 3em !important;
}

.slip-odds {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr 2fr;
  grid-template-rows: 2em;
  background-color: var(--bg-stake);
}

.slip-win {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr 2fr;
  grid-template-rows: 3em;
  background-color: var(--bg-stake);
}

.slip-win-system {
  display: grid;
  width: 100%;
  grid-template-columns: 15.8em 4.5em 9.5em;
  grid-template-rows: 3em;
  background-color: var(--bg-stake);
}

.slip-win-min-right-space {
  margin: 0 0.55em 0 0;
}

.win-max-notification {
  display: grid;
  width: 92%;
  grid-template-columns: auto;
  grid-template-rows: 3.6em;
  padding: 0 0.9em;
  border-radius: 0.4em;
  margin: 0.5em auto;
}

.slip-oddchanges {
  display: grid;
  width: 100%;
  grid-template-columns: 3.5em auto 2.4em;
  grid-template-rows: 3.6em;
  grid-gap: 0em;
  /* background-color: var(--color-main); */
}

.slip-check-system>.MuiCheckbox-root {
  color: var(--color-contrast-slip) !important;
}

.slip-max-button .MuiButton-label {
  font-size: 0.8em !important;

}

.slip-check-system>.MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--color-contrast) !important;
}

.odds-history-button {
  font-size: 0.9em;
}

.slip-check-oddchanges>.MuiCheckbox-root {
  color: var(--grey-11) !important;
}

.slip-check-oddchanges>.MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--color-contrast) !important;
}

.slip-odd-error {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.15em solid var(--red);
  border-radius: 0.4em;
}

.slip-odd-error-content {
  padding: 0.2em 0.9em;
  line-height: 1.2em;
  font-size: 1em;
  width: calc(100% - 100px);
}

.slip-odd-error-content.disabled {
  width: calc(100% - 50px);
}

.slip-odd-error-frame {
  padding: 0 0.5em 0 0;
}

.slip-odd-error-note {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.5em;
}

.slip-odd-error-note i {
  font-size: 1.5em;
  margin: -0.8em 0.25em 0 0;
}

.slip-odd-error-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slip-odd-error-price i {
  font-size: 1.4em;
  margin: 0 0.3em;
}

.slip-odd-error-action i {
  font-size: 1.6em;
}

.invisible {
  visibility: hidden;
}

/* .slip-place-bet {
  background-color: var(--bg-app);
} */

.slip-banner {
  opacity: 1;
  cursor: pointer;
}

.slip-banner-text {
  position: absolute;
  padding: 0.2em;
  font-size: 3em;
  font-weight: bold;
  color: #ffffff;
  font-style: italic;
  text-shadow: 3px 0px 7px rgba(81, 67, 21, 0.8),
    -3px 0px 7px rgba(81, 67, 21, 0.8),
    0px 4px 7px rgba(251, 243, 0, 0.8);
}


/* MEDIAQUERIES */

/* @media (hover: none) {

  .slip-stake {
    grid-template-columns: 18.5em 2em 9.9em;
  }

  .slip-stake-betbuilder {
    grid-template-columns: 21.1em auto 9em;
  }

  .slip-stake-system {
    grid-template-columns: 3.5em 8em auto 10em;
  }

} */

@media (hover: none) and (min-width: 768px) and (max-width:1023px) {

  .green-button,
  .green-bet-button,
  .disabled-button,
  .actived-button {
    height: 3em;
    font-size: 1.2em !important;
  }
}

@media (hover: none) and (min-width: 1024px) {

  .green-button,
  .green-bet-button,
  .disabled-button,
  .actived-button {
    height: 3em;
    font-size: 1.2em !important;
  }
}

@media (hover: hover) {
  .slip-place-bet {
    border-radius: 0 0 0.5em 0.5em;
  }

  .slip-stake {
    grid-template-rows: 3.5em;
    grid-template-columns: 19.1em 0.9em auto;
  }

  .slip-stake-betbuilder {
    grid-template-columns: 21.3em auto 9em;
  }

  .slip-stake-system {
    grid-template-columns: 3.5em 8em auto 8em;
  }

  .grid-divider-place {
    border-bottom-left-radius: 0.5em;
  }

  .odds-history-button {
    font-size: 0.65em;
  }

  .slip-odds {
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 3em;
  }

  .slip-win {
    grid-template-columns: 16.5em 5.5em auto;
  }

  .slip-win-system {
    grid-template-columns: 13em 4em auto;
  }

}


/* Styles for disabling device number pad in mobile version */
.mobile_stake_input_container {
  width: max-content;
  height: max-content;
  position: relative;
}

.mobile_stake_input_overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
}

/* Bet builder edit btn styles */
.betslip_header {
  background-color: var(--title-game-gradient);
}

.betbuilder_edit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  margin-right: 5px;
}

.line-through {
  text-decoration: line-through;
  opacity: 0.7;
}