.my-bets-title {
  display: grid;
  width: 100%;
  grid-template-columns: 50% auto auto 6em;
  grid-template-rows: 1.7em 1.7em;
  border-bottom: 0.15em solid var(--border-gamebox);
}

/* .my-bets-title:first-child {
    border-top: 0.05em solid var(--grey-3);
  } */

.my-bets-option-btn-2cols {
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
  grid-template-rows: 3em;
  border-bottom: 0.1em solid var(--grey-9);
  background-color: var(--grey-7);
}

.my-bets-option-btn-3cols {
  display: grid;
  width: 100%;
  /* grid-template-columns: 25% 25% 50%; */
  grid-template-columns: 29% 26.5% auto;
  grid-template-columns: 29% 26.5% auto;
  grid-template-rows: 3.2em;
  border-bottom: 0.1em solid var(--grey-9);
  background-color: var(--grey-7);
}

.ticket-divider-chip {
  grid-row-end: span 2;
}

@media (hover: hover) {
  .my-bets-option-btn-2cols {
    grid-template-rows: 3.4em;
    /* border-bottom: 0.1em solid var(--grey-1); */
  }

  .my-bets-option-btn-3cols {
    grid-template-rows: 3.4em;
    /* border-bottom: 0.1em solid var(--grey-1); */
  }
}
@media (hover: none) {
  .my-bets-title {
    width: 100%;
    grid-template-columns: 45% auto auto 8.5em;
    grid-template-rows: 2em 2em;
    border-bottom: 0.15em solid var(--border-gamebox);
    /* margin: 0 0 0.15em 0; */
    /* background-color: var(--grey-7); */
  }
}

.ellipsis-text{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}