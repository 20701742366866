.competitor-avatar-bg {
  position: absolute;
  width: 3em;
  height: 3em;
  /* background-image: url(https://api.nubet.com/media/icons/jersey_longsleeve_shadow.png); */
  background-image: url(../../../../media/img/jerseys/jersey_longsleeve_shadow.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 0.15em;
  background-position-y: 0em;
  opacity: 1;
}

.competitor-avatar-bg-short {
  position: absolute;
  width: 3em;
  height: 3em;
  /* background-image: url(https://api.nubet.com/media/icons/jersey_shortsleeve_shadow.png); */
  background-image: url(../../../../media/img/jerseys/jersey_shortsleeve_shadow.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 0.15em;
  background-position-y: 0em;
  opacity: 1;
}

.competitor-avatar-bg-nosleeve {
  position: absolute;
  width: 3em;
  height: 3em;
  /* background-image: url(https://api.nubet.com/media/icons/jersey_nosleeve_shadow.png); */
  background-image: url(../../../../media/img/jerseys/jersey_nosleeve_shadow.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 0.15em;
  background-position-y: 0em;
  opacity: 1;
}

.competitor-avatar-bg-tennis {
  position: absolute;
  width: 3em;
  height: 3em;
  /* background-image: url(https://api.nubet.com/media/icons/jersey_tennis_shadow.png); */
  background-image: url(../../../../media/img/jerseys/jersey_tennis_shadow.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 0.15em;
  background-position-y: 0em;
  opacity: 1;
}

.competitor-avatar-bg-boxing {
  position: absolute;
  width: 3em;
  height: 3em;
  /* background-image: url(https://api.nubet.com/media/icons/jersey_tennis_shadow.png); */
  background-image: url(../../../../media/img/jerseys/jersey_boxing_shadow.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 0.1em;
  background-position-y: 0em;
  opacity: 1;
}

.competitor-avatar-bg-baseball {
  position: absolute;
  width: 3em;
  height: 3em;
  /* background-image: url(https://api.nubet.com/media/icons/jersey_tennis_shadow.png); */
  background-image: url(../../../../media/img/jerseys/jersey_baseball_shadow.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 0.15em;
  background-position-y: 0em;
  opacity: 1;
}

.competitor-avatar-bg-snooker {
  position: absolute;
  width: 3em;
  height: 3em;
  /* background-image: url(https://api.nubet.com/media/icons/jersey_tennis_shadow.png); */
  background-image: url(../../../../media/img/jerseys/jersey_snooker_shadow.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 0.15em;
  background-position-y: 0em;
  opacity: 1;
}
